// Generated by Framer (f26e712)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["aeIjwzQiS", "NXNdEmdE8", "AS6E3lddI"];

const serializationHash = "framer-wV8Tc"

const variantClassNames = {aeIjwzQiS: "framer-v-fzawah", AS6E3lddI: "framer-v-1gq8aoe", NXNdEmdE8: "framer-v-iu3o4c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Big: "NXNdEmdE8", Medium: "aeIjwzQiS", Small: "AS6E3lddI"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, r4IsvrEYT: image ?? props.r4IsvrEYT ?? {src: "https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png?scale-down-to=512 512w,https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png 1920w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "aeIjwzQiS"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string; alt?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, r4IsvrEYT, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aeIjwzQiS", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-fzawah", className, classNames)} data-framer-name={"Medium"} layoutDependency={layoutDependency} layoutId={"aeIjwzQiS"} ref={refBinding} style={{backgroundColor: "var(--token-4e04ecb2-4c8c-4232-853c-c5a9a14468f6, rgb(113, 93, 227))", borderBottomLeftRadius: 14, borderBottomRightRadius: 14, borderTopLeftRadius: 14, borderTopRightRadius: 14, ...style}} {...addPropertyOverrides({AS6E3lddI: {"data-framer-name": "Small"}, NXNdEmdE8: {"data-framer-name": "Big"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 8 + 0)), sizes: "319px", ...toResponsiveImage(r4IsvrEYT)}} className={"framer-ukq7eq"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"ee5znOtqi"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}} {...addPropertyOverrides({AS6E3lddI: {background: {alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 4 + 0)), sizes: "226px", ...toResponsiveImage(r4IsvrEYT)}}, NXNdEmdE8: {background: {alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 8 + 0)), sizes: "360px", ...toResponsiveImage(r4IsvrEYT)}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wV8Tc.framer-14luuyd, .framer-wV8Tc .framer-14luuyd { display: block; }", ".framer-wV8Tc.framer-fzawah { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px; position: relative; width: min-content; }", ".framer-wV8Tc .framer-ukq7eq { aspect-ratio: 1.782122905027933 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 179px); position: relative; width: 319px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-wV8Tc.framer-fzawah { gap: 0px; } .framer-wV8Tc.framer-fzawah > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-wV8Tc.framer-fzawah > :first-child { margin-top: 0px; } .framer-wV8Tc.framer-fzawah > :last-child { margin-bottom: 0px; } }", ".framer-wV8Tc.framer-v-iu3o4c .framer-ukq7eq { height: var(--framer-aspect-ratio-supported, 202px); width: 360px; }", ".framer-wV8Tc.framer-v-1gq8aoe.framer-fzawah { padding: 4px; }", ".framer-wV8Tc.framer-v-1gq8aoe .framer-ukq7eq { height: var(--framer-aspect-ratio-supported, 127px); width: 226px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 195
 * @framerIntrinsicWidth 335
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"NXNdEmdE8":{"layout":["auto","auto"]},"AS6E3lddI":{"layout":["auto","auto"]}}}
 * @framerVariables {"r4IsvrEYT":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameryxHK7Tv0M: React.ComponentType<Props> = withCSS(Component, css, "framer-wV8Tc") as typeof Component;
export default FrameryxHK7Tv0M;

FrameryxHK7Tv0M.displayName = "Helper/ Image Container";

FrameryxHK7Tv0M.defaultProps = {height: 195, width: 335};

addPropertyControls(FrameryxHK7Tv0M, {variant: {options: ["aeIjwzQiS", "NXNdEmdE8", "AS6E3lddI"], optionTitles: ["Medium", "Big", "Small"], title: "Variant", type: ControlType.Enum}, r4IsvrEYT: {__defaultAssetReference: "data:framer/asset-reference,2nWAA3krw8qkTjDMGMwF7IpDJo.png?originalFilename=Rectangle+543468.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FrameryxHK7Tv0M, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})